import { getAnalytics, logEvent } from 'firebase/analytics'
import { type FirebaseApp } from 'firebase/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useAnalytics = (app: FirebaseApp) => {
  const router = useRouter()

  useEffect(() => {
    getAnalytics(app)
  }, [app])

  useEffect(() => {
    const handleTrackRouteChange = (url: string) => {
      const basePath = window.location.origin
      logEvent(getAnalytics(app), 'page_view', {
        page_location: `${basePath}${url}`,
      })
    }

    router.events.on('routeChangeComplete', handleTrackRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleTrackRouteChange)
    }
  }, [app, router.events])
}
