import { colors } from 'fitify-ui'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /*
    http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        -webkit-tap-highlight-color: transparent;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }


    body {
        line-height: 1;
        scroll-behavior: smooth;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    html,
    body {
        background: #EFF7FF;
        color: ${colors.Black};
        margin: 0;
        padding: 0;
        font-family: "Graphik LCG Web", "Helvetica Neue", sans-serif;
        scroll-behavior: smooth;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
    }

    @keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}


        :root {
          --c-white: #fff;
          --c-black: #000000;

          --c-blue50: #0e3262;
          --c-blue100: #2a415f;
          --c-blue200: #314c6f;
          --c-blue300: #38567f;
          --c-blue400: #3f618f;

          --c-blue500: #466c9f;
          --c-blue600: #597ba9;
          --c-blue700: #6b89b2;
          --c-blue800: #7e98bc;
          --c-blue900: #90a7c5;

          --c-yellow500: #ffdd2c;

          --c-red500: #ec530c;

          --c-green100: #dbff9f;
          --c-green200: #c8ff71;
          --c-green400: #b8fa4e;

          --c-gray200: #eeeeee;
          --c-gray400: #bdbdbd;
          --c-gray500: #9e9e9e;
          --c-gray600: #757575;
          --c-gray700: #616161;

          --primary-button-background: var(--c-blue400);
          --primary-button-text-color: var(--c-white);

          --primary-button--hover-background: var(--c-blue200);
          --primary-button--hover-color: var(--c-white);

          --primary-button--focus-background: var(--c-blue200);
          --primary-button--focus-color: var(--c-white);

          --primary-button--disabled-background: var(--c-gray200);
          --primary-button--disabled-color: var(--c-gray400);

          --primary-button--active-background: var(--c-blue100);
          --primary-button--active-color: var(--c-white);

          --secondary-button-background: var(--c-black);
          --secondary-button-text-color: var(--c-white);

          --secondary-button--hover-background: #424242;
          --secondary-button--hover-color: var(--c-white);

          --secondary-button--focus-background: #424242;
          --secondary-button--focus-color: var(--c-white);

          --secondary-button--disabled-background: var(--c-gray200);
          --secondary-button--disabled-color: var(--c-white);

          --secondary-button--active-background: #757575;
          --secondary-button--active-color: var(--c-white);

          --secondary-outline-button-border-color: #000000;
          --secondary-outline-button-background: transparent;
          --secondary-outline-button-text-color: #000000;

          --secondary-outline-button--hover-border-color: #424242;
          --secondary-outline-button--hover-background: transparent;
          --secondary-outline-button--hover-color: #000000;

          --secondary-outline-button--focus-border-color: #757575;
          --secondary-outline-button--focus-background: transparent;
          --secondary-outline-button--focus-color: #757575;

          --secondary-outline-button--disabled-border-color: #757575;
          --secondary-outline-button--disabled-background: transparent;
          --secondary-outline-button--disabled-color: #757575;

          --secondary-outline-button--active-border-color: #757575;
          --secondary-outline-button--active-background: transparent;
          --secondary-outline-button--active-color: #757575;
        }

.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}



`

export const GlobalStyleProxy = GlobalStyle
